<template>
  <div class="dashboard-wraper">
    <!-- Bookmark Property -->
    <div class="form-submit">
      <h4>Saved Listings</h4>
    </div>

    <table
      class="property-table-wrap responsive-table bkmark"
      v-if="!is_loading"
    >
      <tbody>
        <tr>
          <th><i class="fa fa-file-text"></i> Property</th>
          <th></th>
        </tr>

        <tr v-if="bookmarks.length == 0">
          <td colspan="2">You have no saved listings.</td>
        </tr>

        <!-- Item #1 -->
        <tr v-for="(property, i) in bookmarks" :key="`bookmark-${i}`">
          <td class="property-container">
            <router-link :to="{ name: 'page', params: { slug: property.slug }}">
              <img :src="property.image" :alt="property.name" />
            </router-link>
            <div class="title">
              <h4>
                <router-link :to="{ name: 'page', params: { slug: property.slug }}">{{ property.name }}</router-link>
              </h4>
              <span>{{ property.region }} </span>
              <span class="table-property-price"><small>KES </small>{{property.amount }}</span>
            </div>
          </td>
          <td class="action">
            <a href="" class="delete" @click.prevent="deleteBookmark(property)"
              ><i class="lni lni-trash-can"></i> </a
            >
          </td>
        </tr>
      </tbody>
    </table>
    <loading v-if="is_loading" />
  </div>
</template>

<script>
export default {
  mounted() {
    this.fetch();
  },

  data() {
    return {
      bookmarks: [],
      is_loading: true,
    };
  },

  methods: {
    fetch() {
      this.is_loading = true;

      this.$axios.get("/api/v1/my-account/bookmarks").then((response) => {
        this.bookmarks = response.data.bookmarks;
        this.is_loading = false;
      });
    },

    deleteBookmark(bookmark) {
      this.is_loading = true;

      this.$axios.put(`/api/v1/my-account/bookmarks/${bookmark.id}`).then((response) => {
        this.bookmarks = response.data.bookmarks;
        this.is_loading = false;
      });
    }
  },
};
</script>
